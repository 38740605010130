@use '../abstracts/variables';

.p-datepicker {
  height: variables.$height-0;
  width: variables.$width-100p;
  position: absolute !important;
}

.p-datepicker-select-month,
.p-datepicker-select-year {
  color: variables.$color-grey-text;
  background-color: #ffffff;
}

.p-datepicker-input {
  position: absolute;
  width: variables.$width-0;
  height: variables.$height-0;
  border: none !important;
  padding: variables.$space-0 !important;
  margin: variables.$space-0 !important;
  opacity: 0;
}

.menu {
  .p-tieredmenu,
  .p-panelmenu-panel {
    background: transparent !important;
    border: 0 !important;
    position: relative;
    top: variables.$space-500;
  }

  .p-tieredmenu {
    position: fixed;
    display: flex;
    min-width: unset;
  }

  &__labels {
    padding: variables.$space-100;
  }

  .p-tieredmenu-submenu {
    padding: variables.$space-050;
    width: auto;
    min-width: 150px;
    left: 50px !important;
    white-space: nowrap;
    gap: 0;
  }

  .p-panelmenu-header-content {
    display: flex;
    align-items: center;
    text-decoration: none;
    cursor: pointer;

    a {
      text-decoration: none;
      color: variables.$color-white;
      width: variables.$width-100p;
    }
  }

  .p-panelmenu-submenu {
    background-color: #3c9963;
    border-radius: variables.$border-radius-075;
    padding: 0;
  }

  &__panel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: variables.$width-100p;
  }

  &__icon {
    width: variables.$width-250;
    height: variables.$height-300;
    margin: 5px 10px;
  }

  &__link,
  &__link__panel {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: variables.$color-black;
    cursor: pointer;
  }

  &__link__panel {
    color: variables.$color-white;
    margin: 0 5px;
    padding: 5px 20px;

    &:hover {
      font-family: variables.$font-roboto-bold;
      background-color: variables.$color-white;
      border-radius: variables.$border-radius-075;
      color: variables.$color-primary;
    }
  }

  &__link:hover {
    color: variables.$color-primary;
    font-family: variables.$font-primary-bold;
    border-radius: variables.$border-radius-075;
  }

  &__dropdown-icon {
    &.rotated {
      transform: rotate(0);
    }

    &.default {
      transform: rotate(-90deg);
    }
  }
}
