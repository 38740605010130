@import '../abstracts/variables';

.eg-alert {
  &-container {
    border: 1px solid $color-primary;
    border-right-width: $width-050;
    border-radius: $border-radius-050;
    background-color: $color-primary-muted;
    position: relative;
    padding: $space-200;
  }

  &-text {
    font-family: $font-roboto-regular;
    color: $color-grey-text;
    font-size: $font-size-xs;
    font-weight: 400;
    line-height: 14.4px;
  }

  &__error {
    border-color: $color-alert-red;
    background-color: $color-alert-red-muted;
  }

  &__warning {
    border-color: $color-alert-orange;
    background-color: $color-alert-orange-light;
  }

  &__success {
    border-color: $color-green-main;
    background-color: $color-green-light;
  }
}
