@use 'external';
@use 'abstracts/variables';
@use 'abstracts/mixins';
@use 'base/reset';
@use 'base/typography';
@use 'layout/spacing';
@use 'layout/elevation';
@use 'layout/form';
@use 'components/alert';
@use 'components/dialog';
@use 'components/input';
@use 'external/primeng';

.eg-input {
  &__label {
    color: variables.$color-primary;
    font-size: variables.$font-size-xs;
    font-family: variables.$font-input-label;
    font-weight: normal;
    line-height: 14px;
    transition-duration: 300ms;
    transform-origin: 0;
    top: 21px;
    left: 15px;
    padding: 0 5px;
    background: transparent;
  }

  &__prefix {
    @extend %-ix;
    top: 17px;
    right: variables.$space-300;
    padding: variables.$space-0;
  }

  &__suffix {
    @extend %-ix;
    top: 17px;
    right: variables.$space-300;
    padding: variables.$space-0;

    &--icon {
      top: 14px;
      height: 20px;
      width: 20px;
    }
  }

  &__error {
    box-shadow: variables.$shadow-input-error;
    z-index: 101;
    white-space: normal;
  }

  &__input {
    width: variables.$width-100p;
    display: block;
    appearance: none;
    box-sizing: border-box;
    color: variables.$color-grey-text;
    font-family: variables.$font-input-text;
    line-height: 1rem;
    min-height: 17px;
    padding: variables.$padding-input;
    border: variables.$border-input;
    border-radius: variables.$border-radius-input;
    background-color: variables.$color-white;

    /* Makes the placeholder transparent when input is not in focus */
    &:not(:focus-within):not(.no-label) {
      &::-webkit-input-placeholder {
        /* WebKit browsers */
        color: transparent;
      }

      &:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: transparent;
      }

      &::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: transparent;
      }

      &:-ms-input-placeholder {
        /* Internet Explorer 10+ */
        color: transparent;
      }

      &::placeholder {
        color: transparent;
      }

      &:disabled {
        background-color: variables.$color-grey-disabled;
        cursor: no-drop !important;
        color: variables.$color-grey-light;
        font-family: variables.$font-input-text-disabled;
        font-weight: normal;

        & ~ label.eg-input__label {
          background-color: variables.$color-grey-disabled;
          z-index: 100;
        }
      }

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: variables.$space-0;
      }

      &[type='number'] {
        -moz-appearance: textfield;
      }
    }

    &:focus,
    &:focus-within {
      border: 1px solid variables.$color-primary;
      margin: variables.$space-0;
      background: variables.$color-white;
      box-shadow: variables.$shadow-focus;
      outline: none;
    }

    &:not(:placeholder-shown) ~ label,
    &:focus-within ~ label {
      @include mixins.label-transform;
    }

    /* Only Apply to chrome 28 and above */
    @media all and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
      &:-webkit-autofill ~ label {
        @include mixins.label-transform;
      }
    }
  }
}

.error-mark {
  color: variables.$color-alert-red;
  font-size: initial;
  position: absolute;
  right: 6px;
  top: 20px;
}

.label-link {
  color: variables.$color-primary;
  font-size: variables.$font-size-xs;
  font-family: variables.$font-primary-bold;
}

%-ix {
  font-size: variables.$font-size-sm;
  font-family: variables.$font-primary;
  color: variables.$color-primary;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

:host-context(.card-table__input) {
  margin-bottom: variables.$space-0;

  input.input {
    padding: 5px;

    &:disabled {
      border: 0px;
    }

    &:focus,
    &:active {
      border-width: 1px;
    }
  }

  .inline.eg-input__suffix {
    top: 6px;
  }
}

.search-bar {
  .small {
    input.eg-input__input {
      padding: variables.$padding-input-small;
    }

    .eg-input__suffix {
      right: variables.$padding-input-y;
    }

    ng-select.ng-select.ng-select-single {
      .ng-select-container {
        height: 34px;
        border-radius: variables.$border-radius-search;
      }

      .ng-value-container .ng-value {
        color: variables.$color-grey-text;
        font-size: variables.$font-size-xs;
        padding-left: variables.$space-100;
      }

      .ng-value-container .ng-placeholder {
        padding-left: variables.$space-100;
      }
    }

    ng-select.ng-select {
      &.ng-select-single {
        .ng-select-container {
          border-radius: variables.$border-radius-250;
          padding: variables.$padding-input-small;
          z-index: 49;
        }

        &.ng-select-disabled {
          border-radius: variables.$border-radius-250;
        }
      }
    }
  }

  input.eg-input__input {
    border-radius: variables.$border-radius-search;
    font-size: variables.$font-size-xs;
  }

  .eg-input__label {
    top: 10px;
  }

  .eg-input__suffix {
    top: 8px;
  }

  .inline.eg-input__suffix {
    .card-select__dropdown__field--suffix {
      margin-top: variables.$space-100;
    }
  }
}

.card-select__dropdown {
  .search-bar {
    input.eg-input__input {
      height: 1.625rem;
    }

    .inline.eg-input__suffix {
      top: 12px;
    }
  }
}

.search-bar-round {
  input.eg-input__input {
    border-radius: variables.$border-radius-400;
  }
}

.input-52 {
  .input {
    line-height: 14px;
  }
}

textarea {
  &:-webkit-autofill {
    background-color: variables.$color-white !important;
    background-image: none !important;
    background: variables.$color-white;
    -webkit-box-shadow: 0 0 0px 1000px variables.$color-white inset;
    box-shadow: 0 0 0px 1000px variables.$color-white inset;
    -webkit-text-fill-color: variables.$color-grey-text;
    color: variables.$color-grey-text !important;

    &:hover {
      background-color: variables.$color-white !important;
      background-image: none !important;
      background: variables.$color-white;
      -webkit-box-shadow: 0 0 0px 1000px variables.$color-white inset;
      box-shadow: 0 0 0px 1000px variables.$color-white inset;
      -webkit-text-fill-color: variables.$color-grey-text;
      color: variables.$color-grey-text !important;
    }

    &:focus {
      background-color: variables.$color-white !important;
      background-image: none !important;
      background: variables.$color-white;
      -webkit-box-shadow: 0 0 0px 1000px variables.$color-white inset;
      box-shadow: variables.$shadow-focus;
      -webkit-text-fill-color: variables.$color-grey-text;
      color: variables.$color-grey-text !important;
    }
  }
}

select {
  &:-webkit-autofill {
    background-color: variables.$color-white !important;
    background-image: none !important;
    background: variables.$color-white;
    -webkit-box-shadow: 0 0 0px 1000px variables.$color-white inset;
    box-shadow: 0 0 0px 1000px variables.$color-white inset;
    -webkit-text-fill-color: variables.$color-grey-text;
    color: variables.$color-grey-text !important;

    &:hover {
      background-color: variables.$color-white !important;
      background-image: none !important;
      background: variables.$color-white;
      -webkit-box-shadow: 0 0 0px 1000px variables.$color-white inset;
      box-shadow: 0 0 0px 1000px variables.$color-white inset;
      -webkit-text-fill-color: variables.$color-grey-text;
      color: variables.$color-grey-text !important;
    }

    &:focus {
      background-color: variables.$color-white !important;
      background-image: none !important;
      background: variables.$color-white;
      -webkit-box-shadow: 0 0 0px 1000px variables.$color-white inset;
      box-shadow: 0 0 0px 1000px variables.$color-white inset;
      -webkit-text-fill-color: variables.$color-grey-text;
      color: variables.$color-grey-text !important;
    }
  }
}

body,
html {
  font-family: variables.$font-primary;
  min-height: 100vh;
  min-width: 1024px;
  height: variables.$height-100p;
  margin: variables.$space-0;
}

.m-0 {
  margin: variables.$space-0;
}

.svg-to-primary-filter {
  filter: variables.$toPrimaryGreen;
}

.svg-to-primary {
  filter: variables.$toPrimaryGreen;
}

.svg-to-alert {
  filter: variables.$toAlert;
}

.svg-to-error {
  filter: variables.$toError;
}

.svg-to-warning {
  filter: variables.$toWarning;
}

.svg-to-success {
  filter: variables.$toSuccess;
}

.svg-to-blue-filter {
  filter: variables.$toPrimaryBlue;
}

.svg-to-light-grey {
  filter: variables.$toLightGrey;
}

.svg-to-white {
  filter: variables.$toWhite;
}

.svg-empty {
  filter: drop-shadow(0px 0px 1px black);
}

.underline,
.hover-underline:hover {
  text-decoration: underline;
}

.no-underline {
  text-decoration: none;
}

.pointer {
  cursor: pointer;
}

.plain-button {
  background: none;
  color: inherit;
  border: none;
  padding: variables.$space-0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.not-allowed {
  cursor: not-allowed;
}

.success {
  color: variables.$color-green-main;
}

.bold {
  font-family: variables.$font-primary-bold;
  font-weight: normal;
}

.full-width-container {
  width: variables.$width-100p;
}

.full-height {
  height: variables.$height-100p;
}

.absolute-centered {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.inline {
  display: inline;
}

.text-align-center {
  text-align: center;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
  padding-right: variables.$space-150;
}

.hidden {
  display: none !important;
}

.invisible {
  visibility: hidden;
  height: variables.$height-0;
  padding: variables.$space-0;
  margin: variables.$space-0;
  border: none;
  width: variables.$width-0;
}

.flex-grow {
  flex-grow: 1;
}

svg {
  display: block;
}

.no-overflow {
  overflow: hidden;
}

.truncate {
  @extend .no-overflow;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.max-40 {
  max-width: 40%;
}

.link--basic {
  color: variables.$color-primary;
  font-family: variables.$font-link-text;
  font-size: variables.$font-size-sm;
  font-weight: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 16.8px;
  text-align: left;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    font-family: variables.$font-link-text-hover;
  }
}

.btn {
  display: inline-block;
  padding: variables.$space-225 variables.$space-500;
  border-radius: variables.$border-radius-400;
  color: variables.$color-white;
  font-family: variables.$font-roboto-medium;
  font-size: variables.$font-size-2xl;
  font-weight: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.2em;
  text-align: center;
  background-color: variables.$color-primary;
  border: none;
  cursor: pointer;
}

.btn-response {
  padding: 10px variables.$space-250;
  font-size: variables.$font-size-lg;
  background-color: transparent;
  margin: variables.$space-050;
  color: variables.$color-primary;
  border: 1px solid transparent;
  box-shadow: none;

  &:hover,
  &:focus {
    background-color: variables.$color-white;
    border: 1px solid variables.$color-primary-border;
    box-shadow: variables.$shadow-button;
  }
  &:disabled {
    cursor: not-allowed !important;
    background-color: variables.$color-primary-border;
    color: variables.$color-grey-select;

    span {
      color: variables.$color-grey-select;
    }
  }
}

.btn-inactive {
  opacity: 0.2;
  cursor: not-allowed;
}

.btn-container {
  margin: variables.$space-200 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.truncate-cell {
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
}

.btn-filter {
  font-size: variables.$font-size-sm;
  padding: variables.$space-150 variables.$space-250;
  background-color: variables.$color-white;
  color: variables.$color-grey-select;
  border: 1px solid variables.$color-primary-border;
  box-shadow: variables.$shadow-button;

  &:hover,
  &:focus {
    background-color: variables.$color-white;
    border: 1px solid variables.$color-primary-border;
    box-shadow: variables.$shadow-button;
    color: variables.$color-primary;
  }
}

.alert-danger {
  background-color: variables.$color-alert-red-muted;
  border: 1px solid variables.$color-alert-red-light;
  border-right: 4px solid variables.$color-alert-red;
  color: variables.$color-grey-text;
  padding: variables.$space-200;
  margin-bottom: variables.$space-200;
  border-radius: variables.$border-radius-050;
  font-family: variables.$font-primary;
  font-size: variables.$font-size-xs;
  font-weight: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 14.4px;
  text-align: left;
}

.cal-month-view .cal-day-cell,
.cal-month-view .cal-day-cell.cal-today {
  border-radius: variables.$border-radius-200;
  margin: variables.$space-050;
  border: none !important;
  background-color: rgb(240, 240, 240);
  position: relative;

  &.price-rank-1 {
    background-color: rgb(65, 50, 163);
  }

  &.price-rank-2 {
    background-color: rgb(108, 99, 163);
  }

  &.price-rank-3 {
    background-color: rgb(138, 134, 165);
  }

  &.price-rank-4 {
    background-color: rgb(135, 133, 150);
  }

  .cal-day-number {
    color: variables.$color-white;
    opacity: 1;
  }
}

.cal-days .cal-cell-row,
.cal-month-view .cal-days {
  border: none !important;
}

.cal-month-view .cal-cell-row:hover {
  background-color: initial;
}

.cal-month-view .cal-cell-row .cal-cell:hover {
  background: none;
}

.cal-month-view .cal-days .cal-cell-row .cal-cell:hover,
.cal-month-view .cal-cell.cal-has-events.cal-open {
  background-color: rgba(61, 79, 114, 0.8);

  .cal-day-number {
    color: variables.$color-white;
    opacity: 1;
  }
}

.cal-month-view .cal-days .cal-cell-row .cal-cell:hover {
  cursor: pointer;
}

.cal-month-view .cal-day-cell.cal-out-month .cal-day-number {
  opacity: 0.5;
}

.cal-cell-price {
  color: variables.$color-white;
  font-size: variables.$font-size-4xl;
}

.H_ib_body {
  background: variables.$color-white !important;
  padding: variables.$space-200 !important;
  width: 200px;
  font-size: variables.$font-size-xs;
  color: variables.$color-black !important;

  .H_ib_close {
    fill: variables.$color-black !important;

    svg.H_icon {
      fill: variables.$color-black !important;
    }
  }
}

// Cookiebot override
#CybotCookiebotDialogDetailBodyContentTabs {
  a {
    background-color: variables.$color-white;
    color: variables.$color-primary !important;

    &:not(.CybotCookiebotDialogDetailBodyContentTabsItemSelected):hover {
      color: variables.$color-white !important;
    }
  }
}

#CybotCookiebotDialogDetailBodyContent {
  background-color: variables.$color-white !important;
  color: variables.$color-primary !important;

  a:not(:hover) {
    background-color: variables.$color-white;
    color: variables.$color-primary !important;
  }

  .CybotCookiebotDialogDetailBodyContentCookieTypeTable tbody td {
    color: variables.$color-primary !important;
  }
}

#CybotCookiebotDialogBodyButtons a.CybotCookiebotDialogBodyButton,
#CybotCookiebotDialogBodyButtons a.CybotCookiebotDialogBodyButton:hover {
  padding: variables.$space-075;
}

.pac-container {
  z-index: 100000;
}

.ng-select.ng-select-focused.ng-select-edit-focused .ng-value-label {
  color: transparent;
}

.link {
  color: variables.$color-primary;
  text-decoration: none;
}

.release-info-window {
  position: fixed;
  top: 50px;
  width: 960px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999999999;
}

.margin-auto {
  margin: auto;
}

.layout-container {
  margin: 50px 100px;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  transition: all 0.5s ease-out;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-direction: column;
  background: rgba(1, 19, 24, 0.6);
  z-index: 10000;
}

.modal__overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  cursor: pointer;
}

.modal__content {
  position: absolute;
  z-index: 1000;
  box-shadow: 20px 20px 30px rgba(0, 0, 0, 0.2);
  background-color: variables.$color-white;
  padding: 2em;
  border-radius: variables.$border-radius-100;
}

*[ng-reflect-router-link]:focus,
button:focus,
input:focus {
  outline: none;
}

.threedots-dropdown {
  position: absolute;
  right: 0;
  top: 25px;
  background-color: variables.$color-white;
  overflow: auto;
  box-shadow: 0 3px 5px rgba(5, 100, 130, 0.05);
  border: 1px solid variables.$color-blueish-gray;
  z-index: 1;
  display: none;

  &.show {
    display: block;
  }

  a {
    box-sizing: content-box;
    font-size: variables.$font-size-sm;
    height: variables.$height-200;
    cursor: pointer;
    padding: 10px;

    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: start;

    img {
      width: variables.$width-200;
      height: variables.$height-200;
      margin-right: variables.$space-050;
    }
  }

  .cancel {
    color: variables.$color-alert-red;

    &:hover {
      color: variables.$color-alert-red;
    }
  }

  a:hover {
    background-color: variables.$color-primary-sum-box;
    color: variables.$color-primary;
  }
}

.toast-bottom-left {
  left: 70px;
}

.card {
  margin: variables.$space-100;
  box-shadow: 0 3px 5px rgba(5, 100, 130, 0.05);
  border: 1px solid variables.$color-primary-border;
  background-color: variables.$color-white;
  border-radius: variables.$border-radius-050;
}

.tooltip-order {
  &_container {
    opacity: 50%;
  }

  &_price {
    display: flex;
    justify-content: space-between;
    font-size: variables.$font-size-sm;
  }
}

.header-text {
  color: variables.$color-grey-light;
  font-family: variables.$font-table-header;
  font-size: variables.$font-size-xs;
  line-height: 15.6px;
}

.form-reset-button {
  height: variables.$height-400;
  width: 150px;
}

.multiline-tablecell {
  padding-right: variables.$space-400;

  .tablecell-heading {
    font-family: variables.$font-table-text-bold;
    font-size: variables.$font-size-xs;
    margin-bottom: 5px;
    color: variables.$color-grey-text;
  }

  .tablecell-subheading {
    font-family: variables.$font-table-text-secondary;
    font-size: variables.$font-size-xs;
    color: variables.$color-grey-light;
    margin-bottom: 3px;
  }

  .align-right {
    width: variables.$width-100p;
    text-align: right;
  }
}

.list-icon {
  box-sizing: content-box;
  cursor: pointer;

  &.edit {
    width: variables.$width-200;
    height: variables.$height-200;
    padding: 3px;
  }

  &.edit-form {
    display: inline-block;
    width: variables.$width-200;
    height: auto;
    position: relative;
    right: -6px;
  }
}

.eg-button {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &__small {
    padding: variables.$padding-button-small-y variables.$padding-button-small-x;
    font-size: variables.$font-size-xs;
  }

  &__large {
    padding: variables.$padding-button-large-y variables.$padding-button-large-x;
    font-size: variables.$font-size-2xl;
    font-weight: 500;
    line-height: 30px;
  }

  &__default {
    padding: variables.$padding-button-y variables.$padding-button-x;
    font-size: variables.$font-size-xl;
    line-height: 1.2em;
  }

  &__primary {
    color: variables.$color-white;
    background-color: variables.$color-primary;

    &:hover {
      background-color: variables.$color-primary-dark;
    }
  }

  &__danger {
    color: variables.$color-alert-red;
    background-color: variables.$color-white;
    border: 1px solid variables.$color-alert-red-faded !important;

    &:hover {
      background-color: variables.$color-alert-red-muted;
    }
  }

  &__flat {
    background-color: transparent;
    border: none;
  }

  &__secondary {
    color: variables.$color-primary;
    background-color: variables.$color-white;
    box-shadow: 0 3px 5px rgba(1, 24, 19, 0.05);
    border: 1px solid variables.$color-primary-border !important;

    &:hover {
      background-color: variables.$color-primary-muted;
    }
  }

  &__round {
    display: inline-block;
    border-radius: variables.$border-radius-button-round;
    font-family: variables.$font-roboto-medium;
    text-align: center;
    border: none;

    &__saving {
      display: flex;
    }
  }

  &__next {
    @include mixins.nextOrPreviousButton();
    border: 1px solid variables.$color-primary-border;
    box-shadow: variables.$shadow-input-box;

    img {
      margin-left: variables.$space-200;
    }
  }

  &__prev {
    @include mixins.nextOrPreviousButton();
    background-color: transparent;

    img {
      margin-right: variables.$space-200;
    }
  }

  &[disabled],
  &:disabled {
    opacity: 0.3 !important;
    cursor: not-allowed;
  }
}

.eg-icon-button {
  position: relative;
  cursor: pointer;
  margin: variables.$space-050;

  img {
    height: variables.$height-200;
  }

  &__border {
    border: variables.$color-grey-disabled 1px solid;
    background-color: variables.$color-white;
    box-shadow: variables.$shadow-card;
    border-radius: variables.$border-radius-050;
  }

  &__default {
    width: 40px;
    height: 40px;
  }

  &__small {
    width: variables.$width-200;
    height: variables.$height-200;
  }

  &__large {
    width: variables.$width-500;
    height: variables.$height-500;
  }
}

.eg-icon-select {
  display: flex;
  height: inherit;
  width: variables.$width-100p;
  flex-flow: row;
  flex: 0 0 100%;
  align-content: center;

  background-color: variables.$color-grey-disabled;
  border: 1px solid variables.$color-primary-border;
  border-radius: variables.$border-radius-button-round;

  &__item {
    border-radius: variables.$border-radius-button-round;
    cursor: pointer;

    img {
      color: variables.$color-grey-select;
      font-weight: 700;
      font-size: variables.$font-size-sm;
      line-height: 16.8px;
    }

    &--selected {
      box-shadow: 0 3px 5px rgba(1, 24, 19, 0.15);
      background-color: variables.$color-white;
      cursor: default;
      border: variables.$border-selection;
    }
  }
}

.eg-tile-select {
  &--disabled {
    .eg-tile-select__container button {
      background-color: variables.$color-grey-disabled;
      cursor: not-allowed;

      &:hover {
        background-color: variables.$color-grey-disabled;
        //border: 2px solid transparent;
      }
    }
  }

  &__container {
    min-width: unset !important;
    max-width: unset !important;

    & > button {
      color: variables.$color-primary;
      padding: variables.$padding-button-large-y variables.$padding-button-large-x;
      background-color: variables.$color-white;
      border: variables.$color-primary-border;
      box-shadow: variables.$shadow-input-box;
      border-radius: variables.$border-radius-button;
      cursor: pointer;
      border: 2px solid transparent;

      &:hover:not([disabled]),
      &.selected {
        background-color: variables.$color-primary-sum-box;
        border: variables.$border-selection;
        font-family: variables.$font-roboto-bold;
      }

      &[disabled] {
        pointer-events: none;
        background-color: variables.$color-white;
        opacity: 0.4;
      }
    }
  }
}

.eg-radio-select {
  background-color: variables.$color-grey-disabled;
  border: 1px solid variables.$color-primary-border;
  border-radius: variables.$border-radius-radio-select;

  display: flex;
  height: variables.$height-100p;
  width: variables.$width-100p;
  flex-flow: row;
  flex: 0 0 100%;
  align-content: center;

  &__item {
    border-radius: variables.$border-radius-radio-select;
    cursor: pointer;
    padding: variables.$padding-radio-select-y variables.$padding-input-x;

    span {
      color: variables.$color-grey-select;
      font-weight: 700;
      font-size: variables.$font-size-sm;
      line-height: 16.8px;
      font-family: variables.$font-roboto-bold;
    }

    &--selected {
      box-shadow: 0 3px 5px rgba(1, 24, 19, 0.15);
      background-color: variables.$color-white;
      cursor: default;

      span {
        color: variables.$color-primary !important;
      }
    }

    &--disabled {
      pointer-events: none;
    }
  }
}

.eg-toggle {
  position: relative;
  display: inline-block;
  width: variables.$width-400;
  height: variables.$height-200;

  /* Hide default HTML checkbox */
  input {
    opacity: 0;
    width: variables.$width-0;
    height: variables.$height-0;
  }

  /* The slider */
  &__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: variables.$color-grey-light;

    &:before {
      position: absolute;
      content: '';
      height: 14px;
      width: 14px;
      left: 1px;
      bottom: 1px;
      background-color: variables.$color-white;
    }

    /* Rounded sliders */
    &.round {
      border-radius: variables.$border-radius-400;

      &:before {
        border-radius: 50%;
      }
    }
  }
}

input:checked + .eg-toggle__slider {
  background-color: variables.$color-primary;
}

input:focus + .eg-toggle__slider {
  box-shadow: 0 0 1px variables.$color-green-medium;
}

input:checked + .eg-toggle__slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

.eg-pill {
  &--small {
    font-size: variables.$font-size-xs;
  }

  &__status {
    font-family: variables.$font-roboto-flex-bold;
    text-align: center;
    align-items: center;
    display: inline-block;
    min-width: variables.$width-200;
    max-width: 110px;
    overflow: hidden;
    padding: variables.$space-050 10px;
    text-overflow: ellipsis;
    border-radius: variables.$border-radius-pill;
    background-color: variables.$color-grey-input;

    &--grey {
      background-color: variables.$color-grey-input;
      border: 1px solid variables.$color-grey-select;
    }

    &--lightOrange {
      background-color: variables.$color-orange-light;
      color: variables.$color-white;
    }

    &--orange {
      background-color: variables.$color-warning;
      color: variables.$color-white;
    }

    &--lightGreen {
      background-color: variables.$color-primary-light;
      color: variables.$color-white;
    }

    &--green {
      background-color: variables.$color-primary;
      color: variables.$color-white;
    }

    &--blue {
      background-color: variables.$color-waypoints;
      color: variables.$color-white;
    }

    &--lightRed {
      background-color: variables.$color-alert-red-faded;
      color: variables.$color-white;
    }

    &--red {
      background-color: variables.$color-problem;
      color: variables.$color-white;
    }

    &--light-green {
      background-color: variables.$color-primary-modal-inactive;
      border: 1px solid variables.$color-primary;

      span.value {
        color: variables.$color-primary;
      }
    }

    &--light-red {
      background-color: variables.$color-primary-modal-inactive;
      border: 1px solid variables.$color-problem;

      span.value {
        color: variables.$color-problem;
      }
    }

    &--light-orange {
      background-color: variables.$color-alert-orange-light;
      border: 1px solid variables.$color-warning;

      span.value {
        color: variables.$color-warning;
      }
    }

    &--selected {
      background-color: variables.$color-primary;
      color: variables.$color-white;
    }

    &--inactive {
      background-color: variables.$color-primary-modal-inactive;
      color: variables.$color-grey-disabled;

      &:hover {
        background-color: variables.$color-primary;
        color: variables.$color-white;
      }
    }

    &--VERIFICATION,
    &--REBOOKING,
    &--REQUEST_CANCELLATION {
      background-color: variables.$color-warning;
      color: variables.$color-white;
    }

    &--ACCEPTED,
    &--DISPATCHED {
      background-color: variables.$color-primary-light;
      color: variables.$color-white;
    }

    &--BOOKING_CLOSED,
    &--IN_PROGRESS,
    &--ARRIVED {
      background-color: variables.$color-primary;
      color: variables.$color-white;
    }

    &--DONE {
      background-color: variables.$color-waypoints;
      color: variables.$color-white;
    }

    &--TRANSPORT_PROBLEM,
    &--DENIED {
      background-color: variables.$color-problem;
      color: variables.$color-white;
    }

    &--CANCELLED,
    &--REBOOKING_CANCELLED {
      background-color: variables.$color-grey-input;
      border: 1px solid variables.$color-grey-select;
    }
  }
}

.navigation-btn {
  &--disabled {
    opacity: 50%;
    pointer-events: none;
  }
}

.eg-select-illustrated {
  height: 210px;

  &__item {
    &--disabled {
      opacity: 0.6;
      cursor: not-allowed !important;
    }
  }

  &.eg-select-illustrated--small {
    height: 157px;

    li {
      padding: variables.$space-200 variables.$space-225;
    }

    p {
      font-size: variables.$font-size-md !important;
      bottom: -24px !important;
      left: 0 !important;
    }
  }

  li {
    height: variables.$height-100p;
    padding: variables.$space-300 28px;
    margin: variables.$space-0 variables.$space-0 variables.$space-0 10px;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: variables.$color-white;
    border-radius: variables.$border-radius-input;
    cursor: pointer;
    position: relative;
    box-shadow: variables.$shadow-input-box;
    border: 2px solid transparent;
    background-size: 200px;

    &:nth-child(4n + 1) {
      margin-left: variables.$space-0;
    }

    &:hover:not(.disabled),
    &.selected {
      box-shadow: 0 3px 15px variables.$color-shadow-header;
      border: 2px solid variables.$color-primary !important;
      background-color: variables.$color-primary-sum-box;
    }

    .selected__mark {
      display: none;
      position: absolute;
      right: 4px;
      top: -2px;
      width: 26px;
      height: 26px;
    }

    &.selected {
      .selected__mark {
        display: block;
      }

      p {
        color: variables.$color-primary;
        font-family: variables.$font-select-illustrated;
        position: relative;
        bottom: -28px;
        left: -4px;
      }
    }

    p {
      font-size: variables.$font-size-xl;
      font-weight: 400;
      line-height: variables.$height-300;
      color: variables.$color-primary;
      position: relative;
      bottom: -28px;
      left: -4px;
    }

    &.unselected {
      opacity: 0.6;
    }
  }

  &--disabled {
    pointer-events: none;
  }
}

:host ::ng-deep .label-link {
  color: variables.$color-primary;
  font-size: variables.$font-size-xs;
  font-family: variables.$font-primary-bold;
}

%-ix {
  font-size: variables.$font-size-sm;
  font-family: variables.$font-primary;
  color: variables.$color-primary;
}

input {
  &:-webkit-autofill {
    background-color: variables.$color-white !important;
    background-image: none !important;
    background: variables.$color-white;
    -webkit-box-shadow: 0 0 0px 1000px variables.$color-white inset;
    box-shadow: 0 0 0px 1000px variables.$color-white inset;
    -webkit-text-fill-color: variables.$color-grey-text;
    color: variables.$color-grey-text !important;

    &:hover {
      background-color: variables.$color-white !important;
      background-image: none !important;
      background: variables.$color-white;
      -webkit-box-shadow: 0 0 0px 1000px variables.$color-white inset;
      box-shadow: 0 0 0px 1000px variables.$color-white inset;
      -webkit-text-fill-color: variables.$color-grey-text;
      color: variables.$color-grey-text !important;
    }

    &:focus {
      background-color: variables.$color-white !important;
      background-image: none !important;
      background: variables.$color-white;
      -webkit-box-shadow: 0 0 0px 1000px variables.$color-white inset;
      box-shadow: 0 0 0px 1000px variables.$color-white inset;
      -webkit-text-fill-color: variables.$color-grey-text;
      color: variables.$color-grey-text !important;
    }
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: variables.$space-0;
}

:host-context(.card-table__input) {
  margin-bottom: variables.$space-0;

  input.input {
    padding: 5px;

    &:disabled {
      border: 0px;
    }

    &:focus,
    &:active {
      border-width: 1px;
    }
  }

  .inline.eg-input__suffix {
    top: 6px;
  }
}

.date-range {
  display: none;
}

.date-icon {
  display: inline-block;
  width: variables.$width-250;
  height: auto;
  position: relative;
  right: -6px;
}

:host ::ng-deep ngx-daterangepicker-material .md-drppicker {
  font-size: variables.$font-size-xs;
  padding: 10px;
  left: 0;
  width: 220px;

  table {
    border-spacing: 0 2px;
  }

  .calendar {
    thead {
      .prev,
      .next {
        color: variables.$color-primary;
      }

      .month {
        font-size: variables.$font-size-sm;
        color: variables.$color-primary;
        font-family: variables.$font-roboto-medium;
        font-weight: normal;
      }
    }

    td,
    th {
      min-width: unset;
    }

    tr.week-days th {
      font-size: variables.$font-size-xs;
      font-family: variables.$font-roboto-regular;
      font-weight: normal;
      color: variables.$color-grey-cal;
    }
  }

  td.disabled {
    color: variables.$color-grey-cal;
    text-decoration: none;
  }

  td.off.available {
    visibility: hidden;
    height: variables.$height-0;
    margin: variables.$space-0;
    border: none;

    span {
      display: none;
    }
  }

  tr td.available {
    &.today {
      background-color: transparent;
      color: variables.$color-primary;
      font-family: variables.$font-roboto-bold;
      font-weight: normal;

      &.end-date:not(.start-date),
      &.start-date:not(.end-date) {
        background-color: variables.$color-primary-interval;
      }

      &.end-date:not(.start-date) span,
      &.start-date:not(.end-date) span {
        @include mixins.applyStartDateStyles();
      }
    }

    &.active {
      background-color: transparent;
      color: variables.$color-primary;
      font-family: variables.$font-roboto-bold;
      font-weight: normal;

      &.end-date:not(.today),
      &.start-date:not(.today) {
        background-color: variables.$color-primary-interval;
      }

      &.end-date:not(.today) span,
      &.start-date:not(.today) span {
        @include mixins.applyStartDateStyles();
      }

      &.start-date {
        color: variables.$color-primary;
        font-family: variables.$font-roboto-bold;
        font-weight: normal;
        border-radius: variables.$border-radius-200;
        background-color: variables.$color-primary-interval;

        span {
          @include mixins.applyStartDateStyles();
        }
      }
    }

    &.in-range:not(.off) {
      &:not(.end-date) {
        background-color: variables.$color-primary-interval;
      }

      &:first-of-type {
        border-bottom-left-radius: variables.$border-radius-200;
        border-top-left-radius: variables.$border-radius-200;
      }

      &:last-of-type {
        border-bottom-right-radius: variables.$border-radius-200;
        border-top-right-radius: variables.$border-radius-200;
      }
    }
  }
}

.date-container {
  .date-time {
    display: flex;
  }

  .date-icon {
    display: inline-block;
    width: variables.$width-250;
    height: auto;
    position: relative;
    right: -6px;
  }
}

.support-filter-container {
  width: variables.$width-100p;
  border-bottom: 1px solid variables.$color-primary-border;

  eg-input {
    margin-bottom: variables.$space-0;

    input {
      padding: variables.$space-200;
    }

    .search-icon {
      cursor: pointer;
    }

    &.ngx-daterangepicker-action {
      margin-bottom: variables.$space-0;
    }
  }

  .ng-select-container {
    padding: variables.$space-200;
  }
}

eg-date-time,
eg-date,
eg-date-range,
eg-month,
eg-month-range,
eg-time {
  position: relative;
  min-width: 200px;

  &.ng-invalid.ng-touched {
    &:not(.ng-valid.ng-touched) {
      eg-input {
        border: variables.$border-input-invalid;
      }
    }
  }
}

.expandable {
  &__header {
    margin-bottom: variables.$space-100;

    &--title {
      color: variables.$color-grey-text;
    }
  }

  &__header--icon {
    height: variables.$height-100p;
    cursor: pointer;
  }

  &__body {
    width: variables.$width-100p;
  }
}

.check-icon {
  width: variables.$width-300;
}

.truck-icon {
  width: variables.$width-200;
}

.forbidden-icon {
  width: variables.$width-250;
}

.btn-sidebar {
  color: variables.$color-grey-subtitle;
  font-family: variables.$font-roboto-medium;
  font-size: variables.$font-size-sm;
  font-weight: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 16.8px;
  text-align: left;
}

.not-found {
  &__img {
    margin: 40px variables.$space-0 15px variables.$space-0;
    width: 200px;
  }
}

.input-error {
  position: relative;
  box-shadow: variables.$shadow-input-error;
}

.checkmark {
  background-image: url('/assets/icons/save_booking.svg');
  background-repeat: no-repeat;
  background-size: 60%;
  position: absolute;
  top: -140%;
  left: -25%;
  width: 50em;
  height: auto;
}

.select {
  &__item {
    font-size: variables.$font-size-sm;
    line-height: 33.4px;
    cursor: pointer;

    &:hover {
      color: variables.$color-primary;
      font-family: variables.$font-primary-bold;
      background-color: variables.$color-green-light;
      border-radius: variables.$border-radius-075;
    }
  }
}

.login-form {
  display: flex;
  flex-direction: column;
}

.auth-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: variables.$height-100p;
  background-color: variables.$color-primary-back;
}

.auth-content {
  display: flex;
  width: variables.$width-50p;
  min-width: 500px;
  flex-direction: column;
  margin: auto;
}

.label {
  position: relative;
  color: variables.$color-primary;
  font-size: variables.$font-size-xs;
  font-family: variables.$font-roboto-medium;
  font-weight: normal;
  line-height: 14px;
  background: variables.$color-white;

  & > &-top {
    position: absolute;
    top: -6px;
    padding: variables.$space-0 variables.$space-025;
    background-color: variables.$color-white;
  }
}

.multi-select {
  font-size: variables.$font-size-sm;
  font-family: variables.$font-roboto-flex-medium;
  color: variables.$color-primary;
  cursor: pointer;

  &--gray {
    color: variables.$color-grey-light;
  }
}

.card-select__tile__content {
  position: relative;
  right: -24px;
}

.card-select__dropdown__item {
  margin-top: variables.$space-0 !important;
  margin-bottom: variables.$space-0 !important;
  padding-bottom: variables.$space-0 !important;
}

.section-container {
  border: 1px solid variables.$color-primary-border;
  border-radius: variables.$border-radius-050;
  padding: variables.$space-100;
  width: variables.$width-100p;
  margin-bottom: 10px;
}

.item-select {
  color: variables.$color-primary;
  background-color: variables.$color-primary-interval;
  border: 1px solid variables.$color-primary-border;
  box-shadow: variables.$shadow-input-box;
  border-radius: variables.$border-radius-025;
  cursor: pointer;
  white-space: nowrap;

  &.selected {
    color: variables.$color-white;
    background-color: variables.$color-primary;
  }
}

.disable-actions {
  background: variables.$color-grey-cal;
  pointer-events: none;
}

@media screen and (max-width: 1200px) {
  rmb-booking-sidebar {
    display: none !important;
  }
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text--error {
  color: variables.$color-alert-red;
}

.text--warning {
  color: variables.$color-warning;
}

.text--success {
  color: variables.$color-green-main;
}

.text-grey {
  color: variables.$color-grey-subtitle;
}

.text-red {
  color: variables.$color-alert-red;
}

.connector {
  position: relative;

  &::before {
    position: absolute;
    width: variables.$width-100p;
    content: ' ';
    height: variables.$height-025;
    z-index: 1;
    display: block;
    background-color: variables.$color-grey-cal;
  }
}

.train-connection {
  background-color: variables.$color-white;
  border-radius: variables.$border-radius-100;
  border: 1px solid variables.$color-primary-border;
}

.table-icon {
  box-sizing: content-box;
  width: variables.$width-200;
  height: variables.$height-200;
  padding: variables.$space-025;
  cursor: pointer;
}

.table-icon-dispo {
  width: variables.$width-300;
  height: variables.$height-300;
  padding: variables.$space-050;
  cursor: pointer;
}

.row {
  &__image {
    width: 44px;
    height: 44px;
    border-radius: variables.$border-radius-100;
  }
}

.form-list {
  &__container {
    border-radius: variables.$border-radius-075;
    box-shadow: 0 3px 5px variables.$color-shadow-header;
    border: 1px solid variables.$color-primary-border;

    &__header {
      font-size: variables.$font-size-xs;
      font-weight: bold;
    }
  }
}

.table-container {
  max-height: 30vh;
  overflow: auto;
  width: variables.$width-100p;
}

.summary {
  &__container {
    background-color: variables.$color-white;
    border-radius: variables.$border-radius-075;
    box-shadow: 0 3px 5px rgba(57, 57, 57, 0.05);
    border: 1px solid variables.$color-primary-border;
  }

  &__route-details {
    padding: 10px 19px;

    &--column.btn-field:hover {
      cursor: pointer;
    }

    h3 {
      color: variables.$color-grey-light;
      font-size: variables.$font-size-xs;
      margin-top: variables.$space-075;
      margin-bottom: variables.$space-0;
      font-family: variables.$font-roboto-medium;

      &.text-color-black {
        margin-top: 10px;
        color: variables.$color-grey-text;
      }
    }
  }

  &__loading-point__container {
    height: 82px;
    min-height: 82px;
    max-height: 82px;
    position: relative;
    padding: variables.$space-100 variables.$space-225;
    border-top: 1px solid variables.$color-primary-border;
    border-bottom: 1px solid variables.$color-primary-border;
    background-color: variables.$color-primary-back;

    &--divider {
      position: absolute;
      top: 40px;
      width: variables.$width-100p;
      height: variables.$height-025;
      left: 0;
      background-color: variables.$color-primary-border;
    }
  }

  &__loading-point {
    position: relative;
    z-index: 2;
    cursor: pointer;

    &__type {
      color: variables.$color-waypoints;
      font-family: variables.$font-roboto-black;
      font-size: variables.$font-size-sm;
      margin: variables.$space-050 0;
    }

    &__location {
      font-size: variables.$font-size-xs;
      color: variables.$color-grey-light;
      margin: variables.$space-050 0;
    }

    .marker {
      box-sizing: content-box;
      position: relative;
      height: variables.$height-225;
      width: variables.$width-225;
      background-color: variables.$color-white;
      padding: variables.$space-050 variables.$space-075;
      border-radius: variables.$space-200;
    }
  }

  &__goods-table {
    width: variables.$width-100p;
    padding: variables.$space-100 variables.$space-225;
    border-collapse: separate;
    border-spacing: 0 10px;

    &__status {
      img {
        position: relative;
        top: 2px;
        width: variables.$width-400;
      }
    }

    thead tr th {
      position: sticky;
      top: 0;
      background-color: variables.$color-white;
      z-index: 10;
      padding: 10px 0 5px;
      border: 2px solid variables.$color-white;
    }

    tfoot tr td {
      position: sticky;
      bottom: 0;
      background-color: variables.$color-white;
      z-index: 10;
      border: 2px solid variables.$color-white;
    }

    .container-row:hover {
      cursor: pointer;
      background-color: variables.$color-primary-muted;
    }

    tfoot {
      td {
        border-top: 2px solid variables.$color-primary-border;
        padding: 10px 0 5px;
        color: variables.$color-primary;
        font-family: variables.$font-roboto-black;
        font-size: variables.$font-size-sm;
        line-height: 16.41px;
      }

      span.footer--extra {
        font-family: variables.$font-roboto-medium;
      }
    }
  }

  &__additional-services-table {
    background: variables.$color-white;
    border: 1px solid variables.$color-primary-border;
    box-sizing: border-box;
    box-shadow: 0 3px 10px rgba(1, 24, 19, 0.05);
    border-radius: variables.$border-radius-050;
    width: variables.$width-100p;
    border-collapse: separate;
    border-spacing: 0 10px;

    td,
    th {
      padding: 0 11px;
    }

    .indicator {
      position: relative;

      &:before {
        position: absolute;
        content: ' ';
        height: calc(100% + 10px);
        top: 0;
        width: 1px;
        background-color: variables.$color-grey-subtitle;
      }

      &--last {
        position: relative;

        &:before {
          position: absolute;
          content: ' ';
          height: variables.$height-50p;
          top: 0;
          width: 1px;
          background-color: variables.$color-grey-subtitle;
        }

        @include mixins.horizontal-indicator;
      }

      @include mixins.horizontal-indicator;
    }

    tfoot {
      td {
        border-top: 2px solid variables.$color-primary-border;
        padding-top: 11px;
        color: variables.$color-primary;
        font-family: variables.$font-roboto-black;
        font-size: variables.$font-size-sm;
        line-height: 16.41px;
      }

      span.footer--extra {
        font-family: variables.$font-roboto-medium;
      }
    }
  }

  &__info {
    color: variables.$color-grey-subtitle;
    font-family: variables.$font-roboto;
    font-size: variables.$font-size-xs;
    line-height: 14.06px;
  }

  &__form {
    padding: variables.$space-100 variables.$space-200;
    border-top: 2px solid variables.$color-primary-border;
  }

  &__footer {
    padding: variables.$space-200;
    border-top: 2px solid variables.$color-primary-border;
    background-color: variables.$color-white;
  }
}

.destination-separator {
  width: 10px;
}

.navigation-list {
  padding: variables.$space-0;
  margin: variables.$space-0 15px variables.$space-0 15px;
  list-style-type: none;

  li {
    cursor: pointer;
    width: variables.$width-100p;
    padding: variables.$space-050 variables.$space-0;

    &:hover {
      color: variables.$color-primary;
      font-family: variables.$font-primary-bold;
      background-color: variables.$color-green-light;
      border-radius: variables.$border-radius-075;
    }
  }

  &--link {
    display: inline-flex;
    padding: variables.$space-025 variables.$space-100;
    font-size: variables.$font-size-sm;
    font-weight: bold;
    color: variables.$color-grey-light;
    text-decoration: none;
    align-items: center;
    width: variables.$width-100p;
  }

  &--icon {
    height: 20px;
    width: 20px;
  }

  &--link:hover {
    color: variables.$color-primary;
  }

  & .active {
    font-family: variables.$font-primary-bold;
    color: variables.$color-primary-dark;
  }
}

.not-clickable {
  cursor: not-allowed;

  * {
    cursor: not-allowed;
  }
}

eg-card-table.table {
  &.not-clickable .table__row {
    cursor: not-allowed;
  }
}

.dropdown.packaging-type {
  --dropdown-width: $width-100p;
  --dropdown-height: 300px;
}

.pointer {
  cursor: pointer;
}

.heading {
  font-family: variables.$font-roboto-black;
  font-size: variables.$font-size-sm;
  text-align: left;
  color: variables.$color-grey-text;
  font-weight: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 16.8px;
}

span.heading {
  color: variables.$color-primary;
}

.subheading {
  color: variables.$color-grey-text;
  font-family: variables.$font-primary-bold;
  font-size: variables.$font-size-sm;
  text-align: left;
}

span.subheading {
  color: variables.$color-grey-text;
}

.subheading2 {
  font-family: variables.$font-primary;
  font-size: variables.$font-size-xs;
  text-align: left;
  color: variables.$color-grey-subtitle;
}

.scan-message {
  color: variables.$color-grey-text;
  font-size: variables.$font-size-sm;
}

.upload {
  &__input {
    display: none;
  }

  &__button {
    cursor: pointer;
  }

  &__icon {
    background-color: variables.$color-white;
  }

  &__label {
    font-size: variables.$font-size-sm;
    line-height: variables.$height-200;
    font-family: variables.$font-primary-bold;
    margin-left: 10px;
  }

  &__file {
    cursor: pointer;

    .file {
      .file-icon {
        @include mixins.field-pdf(variables.$color-primary, 20px, 32px);
        width: variables.$width-300;
        height: variables.$height-100p;
        display: inline-block;
      }

      .file-name {
        font-size: variables.$font-size-sm;
        font-family: variables.$font-primary-bold;
        color: variables.$color-primary;
      }
    }

    .delete {
      cursor: pointer;
    }
  }
}

.input-label {
  font-family: variables.$font-roboto-medium;
  color: variables.$color-primary-dark;
}

.offer__date-time {
  color: variables.$color-grey-subtitle;
  font-size: variables.$font-size-xs;
  height: 10px;
  margin-top: 4px;
  line-height: 14.91px;

  span.date {
    font-family: variables.$font-roboto-bold;
  }

  span.time {
    font-family: variables.$font-roboto-regular;
  }
}
