@import '../abstracts/variables';

// font weight 300
@font-face {
  font-family: 'RobotoLight';
  src: url('/assets/fonts/Roboto-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

// font weight 400
@font-face {
  font-family: 'RobotoRegular';
  src: url('/assets/fonts/Roboto-Regular.ttf');
  src: url('/assets/fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

// font weight 500
@font-face {
  font-family: 'RobotoMedium';
  src: url('/assets/fonts/Roboto-Medium.ttf');
  src: url('/assets/fonts/Roboto-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

// font weight 700
@font-face {
  font-family: 'RobotoBold';
  src: url('/assets/fonts/Roboto-Bold.ttf');
  src: url('/assets/fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

// font weight 900
@font-face {
  font-family: 'RobotoBlack';
  src: url('/assets/fonts/Roboto-Black.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('./../../assets/fonts/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2') format('woff2');
}

@font-face {
  font-family: 'RobotoFlexLight';
  font-style: normal;
  font-weight: 300;
  src: url('/assets/fonts/RobotoFlex-VariableFont.ttf') format('truetype');
}

@font-face {
  font-family: 'RobotoFlexRegular';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/RobotoFlex-VariableFont.ttf') format('truetype');
}

@font-face {
  font-family: 'RobotoFlexMedium';
  font-style: normal;
  font-weight: 500;
  src: url('/assets/fonts/RobotoFlex-VariableFont.ttf') format('truetype');
}

@font-face {
  font-family: 'RobotoFlexSemiBold';
  font-style: normal;
  font-weight: 600;
  src: url('/assets/fonts/RobotoFlex-VariableFont.ttf') format('truetype');
}

@font-face {
  font-family: 'RobotoFlexBold';
  font-style: normal;
  font-weight: 700;
  src: url('/assets/fonts/RobotoFlex-VariableFont.ttf') format('truetype');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: $font-size-2xl;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  font-feature-settings: 'liga';
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

h1 {
  color: $color-grey-text;
  font-family: $font-roboto-medium;
  font-size: $font-size-5xl;
  font-weight: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.3em;
  text-align: left;
}

h2 {
  color: $color-grey-text;
  font-family: $font-roboto-medium;
  font-size: $font-size-2xl;
  font-weight: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.25em;
  text-align: left;

  .bold {
    font-family: $font-roboto-bold;
  }
}

h3 {
  color: $color-grey-subtitle;
  font-family: $font-roboto-medium;
  font-size: $font-size-sm;
  font-weight: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.25em;
  text-align: left;

  .bold {
    font-family: $font-roboto-bold;
  }

  img {
    position: relative;
    top: 2px;
  }
}

h4 {
  color: $color-grey-text;
  font-family: $font-roboto-regular;
  font-size: $font-size-sm;
  font-weight: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1em;
  text-align: left;

  .bold {
    font-family: $font-roboto-bold;
  }
}

h6 {
  font-size: $font-size-sm;
  color: $color-grey-subtitle;
  line-height: 17px;
  font-family: $font-roboto-medium;
  font-weight: normal;
}

.text-color-primary {
  color: $color-primary;
}

.text-color-secondary {
  color: $color-grey-text;
}

.text-color-tertiary {
  color: $color-grey-subtitle;
}

.text-color-white {
  color: $color-white;
}

.text-color-gray {
  color: $color-grey-subtitle;
}

.text-color-black {
  color: $color-black;
}

.text-color-green {
  color: $color-green-main;
}

.text-color-error {
  color: $color-alert-red;
}

.text-color-orange {
  color: $color-alert-orange;
}

strong {
  font-family: $font-roboto-bold;
  font-weight: normal;
}
