@use '../abstracts/variables';

.eg-checkbox {
  position: relative;

  &:not(:hidden) {
    display: block;
  }

  input {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: variables.$space-0;
    position: absolute;
    width: 1px;
  }

  input:checked + label > svg {
    height: 21px;
    font-size: 1.5rem !important;
  }

  input:checked + label::before {
    background-color: variables.$color-primary;
  }

  label {
    font-size: variables.$font-size-sm;
    padding-left: variables.$space-200;
    color: variables.$color-grey-text;
    line-height: 25px;
    cursor: pointer;
    position: relative;

    span {
      position: relative;
    }

    &::before {
      content: '';
      height: 22px;
      width: 22px;
      float: left;
      border: 1px solid variables.$color-primary-muted-text;
      border-radius: variables.$border-radius-050;
      transition: 0.15s all ease-out;
    }

    svg {
      width: 26px;
      position: absolute;
      left: -25px;
      top: -2px;
    }
  }

  a {
    position: relative;
    z-index: 3;
  }
}

.eg-number-selection {
  color: variables.$color-primary;
  font-weight: 900;
  line-height: 17px;
  padding: variables.$space-250 variables.$space-200 variables.$space-225;
  border: 1px solid variables.$color-primary-border;
  border-radius: variables.$border-radius-input;
  background-color: variables.$color-white;
  font-family: variables.$font-roboto;
  height: variables.$height-input;
  width: variables.$width-100p;
  font-size: variables.$font-size-sm;
  align-items: center;

  &__disabled {
    cursor: not-allowed;
    background-color: variables.$color-grey-disabled;
  }

  span {
    height: variables.$height-150;
    font-size: variables.$font-size-xs;
    font-weight: 900;
    font-style: normal;
    letter-spacing: normal;
    line-height: 16.8px;
  }

  img {
    width: variables.$width-150;
  }

  input {
    border: none;
    font-family: variables.$font-roboto;
    font-size: variables.$font-size-xs;
    width: variables.$width-250;
    text-align: center;
    background: none;
    color: variables.$color-grey-text;
    font-weight: 900;
    line-height: 17px;
    min-height: variables.$height-225;
    padding: variables.$space-250 variables.$space-025 variables.$space-225;
    border-radius: variables.$border-radius-050;
    margin: 1px;
  }
}
