@import 'apps/railmybox/src/styles/abstracts/variables';
@import 'quill/dist/quill.core.css';
@import 'quill/dist/quill.snow.css';

.p-datepicker {
  min-width: 245px;
  top: -15px;
  position: absolute;
  padding: $space-100;
  background: $color-white;
  color: $color-grey-text;
  border-radius: $border-radius-075;

  .p-monthpicker .p-monthpicker-month {
    padding: $space-100;
    transition: box-shadow 0.2s;
    border-radius: $border-radius-075;

    &.p-disabled {
      opacity: 0.3 !important;
      cursor: default;
      pointer-events: none;
    }
    &.p-highlight {
      color: $color-primary;
      background-color: $color-primary-interval;
    }
    &:hover {
      background-color: $color-grey-disabled;
      color: $color-primary;
    }
  }

  .p-datepicker-year,
  .p-datepicker-decade {
    pointer-events: none;
    color: $color-primary;
  }
}
.p-ripple {
  overflow: hidden;
  position: relative;
}

.p-datepicker .p-monthpicker {
  margin: $space-100 $space-0;
}

.p-component,
.p-component * {
  box-sizing: border-box;
}

.p-calendar input {
  position: absolute;
  width: $width-0;
  height: $height-0;
  border: none;
  padding: $space-0;
  margin: $space-0;
  opacity: 0;
}

.p-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  padding: $space-0;
  position: absolute;
  width: 1px;
}

.p-link {
  font-family: $font-primary-bold;
  font-size: $font-size-md;
  text-align: left;
  background-color: transparent;
  margin: $space-0;
  padding: $space-0;
  border: none;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}

.p-hidden {
  display: none;
}

.p-datepicker:not(.p-datepicker-inline) {
  background: $color-white;
  border: 0 none;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
  background: $color-white;
}
.p-datepicker .p-datepicker-header {
  padding: $space-100;
  font-weight: 600;
  margin: $space-0;
  border-bottom: 1px solid #e5e7eb;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev,
.p-datepicker .p-datepicker-header .p-datepicker-next {
  width: $width-400;
  height: $height-400;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover,
.p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover {
  color: $color-grey-text;
  border-color: transparent;
  background: $color-primary-muted;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev:focus-visible,
.p-datepicker .p-datepicker-header .p-datepicker-next:focus-visible {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfdbfe;
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year,
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  font-weight: 600;
  padding: $space-100;
}

.p-toast {
  opacity: 0.9;
}
.p-toast .p-toast-message {
  margin: $space-0 $space-0 $space-200 $space-0;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  border-radius: $border-radius-050;
  overflow-wrap: anywhere;
}
.p-toast .p-toast-message .p-toast-message-content {
  padding: $space-300;
  border-width: 0 0 0 0;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-message-text {
  margin: $space-0 $space-0 $space-0 $space-200;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon {
  font-size: $font-size-4xl;
}
.p-toast .p-toast-message .p-toast-message-content .p-icon:not(.p-toast-icon-close-icon) {
  width: $width-400;
  height: $height-400;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-summary {
  font-weight: 700;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
  margin: $width-100 $width-0 $width-0 $width-0;
}
.p-toast .p-toast-message .p-toast-icon-close {
  width: $width-400;
  height: $height-400;
  border-radius: 50%;
  background: transparent;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-toast .p-toast-message .p-toast-icon-close:hover {
  background: rgba(255, 255, 255, 0.5);
}
.p-toast .p-toast-message .p-toast-icon-close:focus-visible {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-toast .p-toast-message.p-toast-message-info {
  background: $color-blue-light;
  border: solid transparent;
  border-width: 0 0 0 0;
  color: $color-waypoints;
}
.p-toast .p-toast-message.p-toast-message-info .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close {
  color: $color-waypoints;
}
.p-toast .p-toast-message.p-toast-message-success {
  background: $color-primary-muted-text;
  border: solid transparent;
  border-width: 0 0 0 0;
  color: $color-primary-dark;
}
.p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close {
  color: $color-primary-dark;
}
.p-toast .p-toast-message.p-toast-message-warn {
  background: $color-alert-orange-light;
  border: solid transparent;
  border-width: 0 0 0 0;
  color: $color-warning;
}
.p-toast .p-toast-message.p-toast-message-warn .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-warn .p-toast-icon-close {
  color: $color-warning;
}
.p-toast .p-toast-message.p-toast-message-error {
  background: $color-alert-red-faded;
  border: solid transparent;
  border-width: 0 0 0 0;
  color: $color-alert-red;
}
.p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close {
  color: $color-alert-red;
}
.p-toast .p-toast-message.p-toast-message-secondary {
  background: $color-alert-red-light;
  border: solid transparent;
  border-width: 0 0 0 0;
  color: $color-white;
}
.p-toast .p-toast-message.p-toast-message-secondary .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-secondary .p-toast-icon-close {
  color: $color-white;
}
.p-toast .p-toast-message.p-toast-message-contrast {
  background: $color-grey-text;
  border: solid transparent;
  border-width: 0 0 0 0;
  color: $color-white;
}
.p-toast .p-toast-message.p-toast-message-contrast .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-contrast .p-toast-icon-close {
  color: $color-white;
}

// /* Header */
.p-datepicker-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.p-datepicker-header .p-datepicker-title {
  margin: $space-0 auto;
}

.p-datepicker-prev,
.p-datepicker-next {
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}

// /* DatePicker Table */
.p-datepicker table {
  width: $width-100p;
  border-collapse: collapse;
}

.p-datepicker td > span {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: $space-0 auto;
  overflow: hidden;
  position: relative;
}

// /*  Button Bar */
.p-datepicker-buttonbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// /* Time Picker */
.p-timepicker {
  display: flex;
  justify-content: center;
  align-items: center;
}

.p-timepicker button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.p-cascadeselect {
  display: inline-flex;
  cursor: pointer;
  position: relative;
  user-select: none;
}

.p-cascadeselect-trigger {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.p-cascadeselect-label {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  flex: 1 1 auto;
  width: 1%;
  text-overflow: ellipsis;
  cursor: pointer;
}

.p-cascadeselect-label-empty {
  overflow: hidden;
  visibility: hidden;
}

.p-cascadeselect-item {
  cursor: pointer;
  font-weight: normal;
  white-space: nowrap;
}

.p-cascadeselect-item-content {
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.p-timepicker > div {
  display: flex;
  align-items: center;
  flex-direction: column;
}

p-calendar.ng-dirty.ng-invalid > .p-calendar > .p-inputtext {
  border-color: $color-alert-red-light;
}

.p-calendar:not(.p-calendar-disabled).p-focus > .p-inputtext {
  outline: 1px solid var(--p-focus-ring-color);
  outline-offset: -1px;
  box-shadow: none;
  border-color: $color-blue-light;
}

.p-datepicker {
  padding: $space-150;
  background: $color-white;
  color: $color-grey-text;
  border: 1px solid #cbd5e1;
  border-radius: $border-radius-075;
}
.p-datepicker:not(.p-datepicker-inline) {
  background: $color-white;
  border: 1px solid rgba($color-primary, 0.1);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
}
.p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
  background: $color-white;
}
.p-datepicker .p-datepicker-header {
  padding: $space-0 $space-0 $space-100 $space-0;
  color: $color-grey-text;
  background: $color-white;
  font-weight: 500;
  margin: $space-0;
  border-bottom: 1px solid rgba($color-primary, 0.1);
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev,
.p-datepicker .p-datepicker-header .p-datepicker-next {
  width: 1.75rem;
  height: 1.75rem;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover,
.p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover {
  color: $color-teal-dark;
  border-color: transparent;
  background: $color-blueish-gray;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev:focus-visible,
.p-datepicker .p-datepicker-header .p-datepicker-next:focus-visible {
  outline: 1px solid var(--p-focus-ring-color);
  outline-offset: 2px;
  box-shadow: none;
}
.p-datepicker .p-datepicker-header .p-datepicker-title {
  line-height: 1.75rem;
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year,
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  color: $color-grey-text;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
  font-weight: 500;
  padding: $space-050;
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year:enabled:hover,
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month:enabled:hover {
  color: $color-primary;
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  margin-right: $space-100;
}
.p-datepicker table {
  font-size: $font-size-md;
  margin: $space-100 $space-0 $space-0 $space-0;
}
.p-datepicker table th {
  padding: $space-050;
}
.p-datepicker table th > span {
  width: $width-400;
  height: $height-400;
  font-weight: 500 !important;
}
.p-datepicker table td {
  padding: $space-050;
}
.p-datepicker table td > span {
  font-weight: 600;
  width: $width-400;
  height: $height-400;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
  border: 1px solid transparent;
}
.p-datepicker table td > span.p-highlight {
  color: $color-primary;
  background: rgba($color-primary, 0.1);
}
.p-datepicker table td > span:focus {
  outline: 1px solid var(--p-focus-ring-color);
  outline-offset: 2px;
  box-shadow: none;
}
.p-datepicker table td.p-datepicker-today > span {
  background: rgba($color-primary, 0.1);
  color: $color-grey-text;
  border-color: $color-primary;
}
.p-datepicker table td.p-datepicker-today > span.p-highlight {
  color: $color-primary;
  background: rgba($color-primary, 0.1);
}
.p-datepicker .p-datepicker-buttonbar {
  padding: $space-100 $space-0 $space-0 $space-0;
  border-top: 1px solid rgba($color-primary, 0.1);
}
.p-datepicker .p-datepicker-buttonbar .p-button {
  width: auto;
}
.p-datepicker .p-timepicker {
  border-top: 1px solid rgba($color-primary, 0.1);
  padding: $space-100 $space-0;
}
.p-datepicker .p-timepicker button {
  width: 1.75rem;
  height: 1.75rem;
  color: $color-blueish-gray;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
}
.p-datepicker .p-timepicker button:enabled:hover {
  color: #475569;
  border-color: transparent;
  background: $color-primary-modal-nav;
}
.p-datepicker .p-timepicker button:focus-visible {
  outline: 1px solid var(--p-focus-ring-color);
  outline-offset: 2px;
  box-shadow: none;
}
.p-datepicker .p-timepicker button:last-child {
  margin-top: 0.2em;
}
.p-datepicker .p-timepicker span {
  font-size: $font-size-md;
}
.p-datepicker .p-timepicker > div {
  padding: $space-0 $space-100;
}
.p-datepicker.p-datepicker-timeonly .p-timepicker {
  border-top: 0 none;
}
.p-datepicker .p-monthpicker {
  margin: $space-100 $space-0 $space-0 $space-0;
}
.p-datepicker .p-monthpicker .p-monthpicker-month {
  padding: $space-050;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
  border-radius: $border-radius-075;
}
.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
  color: $color-primary;
  background: rgba($color-primary, 0.1);
}
.p-datepicker .p-yearpicker {
  margin: $space-100 $space-0 $space-0 $space-0;
}
.p-datepicker .p-yearpicker .p-yearpicker-year {
  padding: $space-050;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
  border-radius: $border-radius-075;
}
.p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight {
  color: $color-primary;
  background: rgba($color-primary, 0.1);
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
  border-left: 1px solid rgba($color-primary, 0.1);
  padding-right: $space-150;
  padding-left: $space-150;
  padding-top: $space-0;
  padding-bottom: $space-0;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:first-child {
  padding-left: $space-0;
  border-left: 0 none;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:last-child {
  padding-right: $space-0;
}
.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
  background: $color-primary-modal-nav;
}
.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus {
  outline: 1px solid var(--p-focus-ring-color);
  outline-offset: 2px;
  box-shadow: none;
}
.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):not(.p-highlight):hover {
  background: $color-primary-modal-nav;
}
.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):focus {
  outline: 1px solid var(--p-focus-ring-color);
  outline-offset: 2px;
  box-shadow: none;
}
.p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):not(.p-highlight):hover {
  background: $color-primary-modal-nav;
}
.p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):focus {
  outline: 1px solid var(--p-focus-ring-color);
  outline-offset: 2px;
  box-shadow: none;
}

.p-calendar {
  display: flex;
}

p-calendar.p-calendar-clearable .p-inputtext {
  padding-right: 2.5rem;
}
p-calendar.p-calendar-clearable .p-calendar-clear-icon {
  color: $color-blueish-gray;
  right: 0.75rem;
}

p-calendar.p-calendar-clearable .p-calendar-w-btn .p-calendar-clear-icon {
  color: $color-blueish-gray;
  right: 3.25rem;
}

@media screen and (max-width: 769px) {
  .p-datepicker table th,
  .p-datepicker table td {
    padding: $space-050;
  }
}

.p-disabled,
.p-component:disabled {
  opacity: 0.5;
  font-weight: 500 !important;
}

.p-calendar {
  .p-datepicker-multiple-month {
    left: -310px !important;

    button:disabled {
      background-color: transparent !important;
      color: $color-black !important;
    }
  }
}

.p-button {
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  padding: 10px $space-250;
  background-color: transparent;
  color: $color-primary;
}

.p-cascadeselect-group-icon {
  margin-left: auto;
  padding-left: $space-100;
  display: inline-flex;
}

.p-cascadeselect-items {
  margin: $space-0;
  padding: $space-0;
  list-style-type: none;
}

.p-fluid .p-cascadeselect {
  display: flex;
}

.p-fluid .p-cascadeselect .p-cascadeselect-label {
  width: 1%;
}

.p-cascadeselect-sublist {
  position: absolute;
  min-width: $width-100p;
  z-index: 1;
  display: none;
}

.p-cascadeselect-item-active {
  overflow: visible !important;
}

.p-cascadeselect-item-active > .p-cascadeselect-sublist {
  display: block;
  left: 100%;
  top: 0;
}

.p-cascadeselect-clear-icon {
  position: absolute;
  top: 50%;
  margin-top: -0.5rem;
  cursor: pointer;
}

.p-cascadeselect-clearable {
  position: relative;
}

/* Modal */
.p-overlay-modal .p-cascadeselect-sublist {
  position: relative;
}

.p-overlay-modal .p-cascadeselect-item-active > .p-cascadeselect-sublist {
  left: 0;
}

.p-cascadeselect {
  background: transparent;
}
.p-cascadeselect:not(.p-disabled).p-focus {
  box-shadow: none;
}
.p-cascadeselect .p-cascadeselect-label {
  background: transparent;
  border: 0 none;
  padding: $space-100 $space-050;
}
.p-cascadeselect .p-cascadeselect-label:enabled:focus {
  outline: 0 none;
  box-shadow: none;
}
.p-cascadeselect .p-cascadeselect-trigger {
  background: transparent;
  width: $width-400;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.p-cascadeselect.p-invalid.p-component {
  border-color: $color-alert-red-light;
}
.p-cascadeselect.p-variant-filled {
  background-color: $color-blueish-gray;
}
.p-cascadeselect.p-variant-filled:enabled:hover {
  background-color: $color-blueish-gray;
}
.p-cascadeselect.p-variant-filled:enabled:focus {
  background-color: $color-white;
}

.p-cascadeselect-panel {
  background: $color-white;
  color: $color-grey-text;
  border: 1px solid #e2e8f0;
  border-radius: $border-radius-075;
}
.p-cascadeselect-panel .p-cascadeselect-items {
  padding: $space-050 $space-050;
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item {
  margin: $space-025 $space-0;
  border: 0 none;
  color: $color-grey-text;
  border-radius: $border-radius-050;
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item:first-child {
  margin-top: $space-0;
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item.p-highlight {
  color: $color-primary;
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item.p-highlight.p-focus {
  background: transparent;
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item:not(.p-highlight):not(.p-disabled):hover {
  color: $color-primary;
  background: $color-primary-modal-nav;
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-item-content {
  padding: $space-100 $space-150;
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-group-icon {
  font-size: $font-size-sm;
}

.p-input-filled .p-cascadeselect {
  background: $color-blueish-gray;
}
.p-input-filled .p-cascadeselect:not(.p-disabled):hover {
  background-color: $color-blueish-gray;
}
.p-input-filled .p-cascadeselect:not(.p-disabled).p-focus {
  background-color: $color-white;
}

p-cascadeselect.ng-dirty.ng-invalid > .p-cascadeselect {
  border-color: $color-alert-red-light;
}

p-cascadeselect.p-cascadeselect-clearable .p-cascadeselect-label {
  padding-right: $space-150;
}
p-cascadeselect.p-cascadeselect-clearable .p-cascadeselect-clear-icon {
  color: $color-blueish-gray;
  right: 2.5rem;
}

.p-overlay-modal .p-cascadeselect-sublist .p-cascadeselect-panel {
  box-shadow: none;
  border-radius: 0;
  padding: $space-050 $space-0 $space-050 $space-100;
}
.p-overlay-modal .p-cascadeselect-item-active > .p-cascadeselect-item-content .p-cascadeselect-group-icon {
  transform: rotate(90deg);
  margin-left: 0.4rem;
}
