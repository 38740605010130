$color-blueish-gray: #dbe8ed;
$color-teal-dark: #056482;

// PRIMARY blues
$color-primary: #0e813f;
$color-primary-dark: #0d7337;
$color-primary-header: #0e813f;
$color-primary-header-accent: #2bb959;
$color-primary-placeholder: #7f7fca;
$color-primary-light: #86c09f;
$color-primary-modal-placeholder: #8e8ed2;
$color-primary-inactive-text: #9eccb2;
$color-primary-modal-inactive: #aad2bb;
$color-primary-muted-btn: #b6d9c5;
$color-primary-muted-text: #c4e0d0;
$color-primary-muted: #fafcfb;
$color-primary-interval: #e8f3ed;
$color-primary-modal-nav: #f9f9f9;
$color-primary-modal-border: #f4f4f4;
$color-primary-info-box: #edf5f1;
$color-primary-muted-arrow: #f5f9f7;
$color-primary-sum-box: #f9f9fe;
$color-primary-back: #fcfcfe; // main background color
$color-waypoints: #316090;
$color-blue-light: #9ab8d6;
$color-table-selected-row: #f2f2fd;

// SHADOWS
$color-shadow-header: rgba(35, 37, 58, 0.05);
$shadow-input-box: 0 3px 5px rgba(39, 39, 168, 0.15);
$shadow-card: 0 3px 8px rgba(39, 39, 168, 0.15);
$shadow-card-item: 0 3px 5px rgba(5, 100, 130, 0.05);
$shadow-dropdown: 0 3px 20px rgba(39, 39, 168, 0.15);
$shadow-input-error: 0 3px 5px rgba(36, 36, 46, 0.1);
$shadow-button: 0 3px 10px rgba(35, 38, 48, 0.1);
$shadow-breadcrumb: 10px 3px 10px rgba(35, 38, 48, 0.05);
$shadow-tooltip: 0 3px 15px rgba(39, 39, 168, 0.14);
$shadow-focus: 0 3px 5px rgba(5, 100, 130, 0.15);

// SECONDARY greys
$color-grey-text: #393939; // main text, input
$color-grey-light: #787878;
$color-grey-subtitle: #9c9c9c;
$color-grey-select: #b4b4b4;
$color-grey-cal: #e6e6e6;
$color-primary-border: #eaeaea;
$color-grey-disabled: #f5f5f5;
$color-grey-input: #f9f9f9; // main border color
$color-white: #ffffff;
$color-black: #000000;
$color-background-support: #fbfbfb;

// SECONDARY greens
$color-green-main: #73cc88;
$color-green-medium: #16ad4a;
$color-green-light: #d9eddd;
$color-green-muted: #edf8f0;

// ALERT reds
$color-alert-red: #ff0026;
$color-problem: #ed3f59;
$color-alert-red-light: #ff2d4d;
$color-alert-red-faded: #fdc7d1;
$color-alert-red-muted: #fff4f4;

// ALERT oranges
$color-alert-orange: #fbb03b;
$color-warning: #ffa233;
$color-alert-orange-light: #fef4e7;
$color-orange-light: #fac88b;

// SVG COLOR FILTER
$toPrimaryGreen: brightness(0%) invert(34%) sepia(72%) saturate(548%) hue-rotate(93deg) brightness(96%) contrast(91%);
$toPrimaryBlue: brightness(0%) invert(31%) sepia(5%) saturate(7111%) hue-rotate(171deg) contrast(82%);
$toError: brightness(0%) invert(23%) sepia(91%) saturate(6981%) hue-rotate(346deg) brightness(117%) contrast(121%);
$toAlert: brightness(0%) invert(84%) sepia(35%) saturate(1930%) hue-rotate(328deg) brightness(100%) contrast(98%);
$toWarning: brightness(0%) invert(83%) sepia(66%) saturate(6475%) hue-rotate(332deg) brightness(96%) contrast(103%);
$toSuccess: brightness(0%) invert(82%) sepia(13%) saturate(1257%) hue-rotate(80deg) brightness(89%) contrast(85%);
$toLightGrey: brightness(0%) invert(71%) sepia(0%) saturate(257%) hue-rotate(138deg) brightness(88%) contrast(88%);
$toWhite: brightness(0%) invert(100%) sepia(0%) saturate(0%) hue-rotate(310deg) brightness(105%) contrast(104%);

$font-roboto: 'Roboto', Arial, Helvetica, sans-serif;
$font-roboto-regular: 'RobotoRegular', Arial, Helvetica, sans-serif;
$font-roboto-medium: 'RobotoMedium', Arial, Helvetica, sans-serif;
$font-roboto-bold: 'RobotoBold', Arial, Helvetica, sans-serif;
$font-roboto-flex-medium: 'RobotoFlexMedium', Arial, Helvetica, sans-serif;
$font-roboto-flex-bold: 'RobotoFlexBold', Arial, Helvetica, sans-serif;
$font-roboto-black: 'RobotoBlack', Arial, Helvetica, sans-serif;
$font-roboto-flex-regular: 'RobotoFlexRegular', Arial, Helvetica, sans-serif;
$font-roboto-flex-light: 'RobotoFlexLight', Arial, Helvetica, sans-serif;

$font-primary: $font-roboto-regular;
$font-primary-bold: $font-roboto-bold;
$font-link: $font-roboto-medium;

$font-input-text: $font-primary-bold;
$font-input-text-disabled: $font-primary;
$font-input-label: $font-roboto-medium;

$font-table-header: $font-roboto-medium;
$font-table-text: $font-roboto-medium;
$font-table-text-bold: $font-roboto-bold;
$font-table-text-secondary: $font-roboto-medium;

$font-link-text: $font-roboto-medium;
$font-link-text-hover: $font-roboto-bold;

$font-select-illustrated: $font-roboto-bold;

$space-0: 0; // 0rem
$space-025: 2px; // 0.125rem
$space-050: 4px; // 0.25rem
$space-075: 6px; // 0.375rem
$space-100: 8px; // 0.5rem
$space-150: 12px; // 0.75rem
$space-200: 16px; // 1rem
$space-225: 18px; // 1.125rem
$space-250: 20px; // 1.25rem
$space-300: 24px; // 1.5rem
$space-400: 32px; // 2rem
$space-500: 48px; // 3rem
$space-full: 100%;

$border-radius-025: $space-025;
$border-radius-050: $space-050;
$border-radius-075: $space-075;
$border-radius-100: $space-100;
$border-radius-200: $space-200;
$border-radius-250: $space-250;
$border-radius-400: $space-400;
$border-radius-500: $space-500;
$border-radius-full: 100px;

$border-radius-dropdown: $border-radius-075;
$border-radius-input: $border-radius-050;
$border-radius-pill: $border-radius-full;
$border-radius-tooltip: $border-radius-050;
$border-radius-table: $border-radius-075;
$border-radius-table-row: $border-radius-075;
$border-radius-button: $border-radius-100;
$border-radius-radio-select: $border-radius-250;
$border-radius-button-round: $border-radius-full;
$border-radius-button-navigation: $border-radius-500;
$border-radius-search: $border-radius-500;

$border-selection: 1px solid $color-primary;

$padding-radio-select-x: $space-150;
$padding-radio-select-y: $space-150;
$padding-input-x: $space-225;
$padding-input-y: $space-200;
$padding-input-small-x: $space-200;
$padding-input-small-y: $space-100;
$padding-button-x: $space-300;
$padding-button-y: $space-150;
$padding-button-small-x: $space-200;
$padding-button-small-y: $space-100;
$padding-button-large-x: $space-500;
$padding-button-large-y: $space-200;

$width-0: 0;
$width-025: 2px; // 0.125rem
$width-050: 4px; // 0.25rem
$width-075: 6px; // 0.375rem
$width-100: 8px; // 0.5rem
$width-150: 12px; // 0.75rem
$width-200: 16px; // 1rem
$width-225: 18px; // 1.125rem
$width-250: 20px; // 1.25rem
$width-300: 24px; // 1.5rem
$width-400: 32px; // 2rem
$width-500: 48px; // 3rem
$width-100p: 100%;
$width-50p: 50%;

$height-0: 0;
$height-025: 2px; // 0.125rem
$height-050: 4px; // 0.25rem
$height-075: 6px; // 0.375rem
$height-100: 8px; // 0.5rem
$height-150: 12px; // 0.75rem
$height-200: 16px; // 1rem
$height-225: 18px; // 1.125rem
$height-250: 20px; // 1.25rem
$height-300: 24px; // 1.5rem
$height-400: 32px; // 2rem
$height-500: 48px; // 3rem
$height-100p: 100%;
$height-50p: 50%;
$height-input: 52px; // 3.25rem

$font-size-2xs: 10px; // 0.625rem
$font-size-xs: 12px; // 0.75rem
$font-size-sm: 14px; // 0.875rem
$font-size-md: 16px; // 1rem
$font-size-lg: 18px; // 1.125rem
$font-size-xl: 20px; // 1.25rem
$font-size-2xl: 24px; // 1.5rem
$font-size-3xl: 28px; // 1.75rem
$font-size-4xl: 32px; // 2rem
$font-size-5xl: 36px; // 2.25rem
$font-size-6xl: 40px; // 2.5rem
$font-size-7xl: 48px; // 3rem
$font-size-8xl: 56px; // 3.5rem
$font-size-9xl: 64px; // 4rem
$font-size-10xl: 72px; // 4.5rem
$font-size-11xl: 80px; // 5rem
$font-size-12xl: 96px; // 6rem

$table-selected-row-color: $color-table-selected-row;
