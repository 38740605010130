@import 'apps/railmybox/src/styles/abstracts/variables';

.eg-checkbox {
  position: relative;

  &:not(:hidden) {
    display: block;
  }

  input {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: $space-0;
    position: absolute;
    width: 1px;
  }

  input:checked + label > svg {
    height: 21px;
    font-size: 1.5rem !important;
  }

  input:checked + label::before {
    background-color: $color-primary;
  }

  label {
    font-size: $font-size-sm;
    padding-left: $space-200;
    color: $color-grey-text;
    line-height: 25px;
    cursor: pointer;
    position: relative;

    span {
      position: relative;
    }

    &::before {
      content: '';
      height: 22px;
      width: 22px;
      float: left;
      border: 1px solid $color-primary-muted-text;
      border-radius: $border-radius-050;
      transition: 0.15s all ease-out;
    }

    svg {
      width: 26px;
      position: absolute;
      left: -25px;
      top: -2px;
    }
  }

  a {
    position: relative;
    z-index: 3;
  }
}

.eg-number-selection {
  color: $color-primary;
  font-weight: 900;
  line-height: 17px;
  padding: $space-250 $space-200 $space-225;
  border: 1px solid $color-primary-border;
  border-radius: $border-radius-input;
  background-color: $color-white;
  font-family: $font-roboto;
  height: $height-input;
  width: $width-100p;
  font-size: $font-size-sm;
  align-items: center;

  &__disabled {
    cursor: not-allowed;
    background-color: $color-grey-disabled;
  }

  span {
    height: $height-150;
    font-size: $font-size-xs;
    font-weight: 900;
    font-style: normal;
    letter-spacing: normal;
    line-height: 16.8px;
  }

  img {
    width: $width-150;
  }

  input {
    border: none;
    font-family: $font-roboto;
    font-size: $font-size-xs;
    width: $width-250;
    text-align: center;
    background: none;
    color: $color-grey-text;
    font-weight: 900;
    line-height: 17px;
    min-height: $height-225;
    padding: $space-250 $space-025 $space-225;
    border-radius: $border-radius-050;
    margin: 1px;
  }
}
