@import '../abstracts/variables';

.eg-modal {
  &-background {
    background: rgba(1, 19, 24, 0.6);
  }

  &-body {
    position: relative;
    background-color: $color-primary-sum-box;
    text-align: left;
    border-radius: $border-radius-100;
    max-height: 800px !important;
    overflow-y: auto;
    overflow-x: hidden;

    & > form {
      padding: $space-0 $space-300;

      & > div {
        position: relative;
      }
    }

    .eg-modal-title {
      border-radius: 10px 10px 0 0;
      display: inline-block;
      color: $color-primary;
      width: $width-100p;
      font-size: $font-size-sm;
      font-family: $font-primary-bold;
      line-height: 60px;
      background-color: $color-white;
      text-align: left;
      padding-left: $space-300;
      margin: $space-0;
      border-bottom: 1px solid $color-primary-border;
    }

    p {
      line-height: $height-300;
    }

    .close-dialog {
      position: absolute;
      right: 25px;
      top: 16px;
      color: $color-grey-text;
      cursor: pointer;
    }
  }
}

.sidepanel-right {
  .eg-modal {
    height: 100vh;
    &-overlay,
    &-container,
    &-body {
      height: 100vh;
      max-height: 100vh !important;
    }
    &-body {
      border-radius: 0;
      position: absolute;
      right: 0;
    }
  }
}
